<template>
  <div id="dashboard">
    <document-saved-searches-form />
    <base-layout>
      <template v-slot:drawer-left>
        <sidebar v-if="userFiltersAreSet" />
      </template>

      <template v-slot:searchbar>
        <!-- <search-bar /> -->
        <search-bar
          on-search-action="getAllTimelineItems"
          set-query-action="setQuery"
          search-query="searchParameterQuery"
          :query-length="1000"
          :placeholder="$t('document.searchInDocuments')"
        />
      </template>

      <template v-slot:main-content>
        <v-container
          fluid
        >
          <v-row
            v-if="timelineItemsCountTotal > 0 && statisticsTrend"
          >
            <v-col cols="12">
              <v-expansion-panels
                :focusable="false"
                v-if="trendAnalyticsExpanded"
                v-model="singlepanel"
                multiple
              >
                <trend-analytics-chart
                  statisticsTrendType="statisticsTrend"
                  statisticsTimelineType="getAllTimelineItems"
                />
              </v-expansion-panels>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col
              v-if="timelineItemsCountTotal >= 0"
              cols="12"
              lg="6"
              xl="6"
            >
              <list-timeline
                nextItemUrlString="nextItemUrl"
                setNextItemUrlString="setNextItemUrl"
                timelineItemsString="timelineItems"
                timelineItemsCountTotalString="timelineItemsCountTotal"
                timelineItemsHasMoreString="timelineItemsHasMore"
                getAllTimelineItemsNextString="getAllTimelineItemsNext"
                getAllTimelineItemsString="getAllTimelineItems"
                maxDateRangeTimelineItemsTotalString="maxDateRangeTimelineItemsTotal"
                maxDateRangeTimelineItemsHasMoreString="maxDateRangeTimelineItemsHasMore"
              />
            </v-col>
            <v-col
              v-if="timelineItemsCountTotal > 0 && statisticsTrend"
              cols="12"
              lg="6"
              xl="6"
              class="d-none d-lg-block"
            >
              <v-expansion-panels
                :focusable="false"
                v-model="panel"
                multiple
              >
                <trend-analytics-chart
                  v-if="!trendAnalyticsExpanded"
                  statisticsTrendType="statisticsTrend"
                  statisticsTimelineType="getAllTimelineItems"
                />
                <list-stakeholders
                  title="Stakeholderactiviteit"
                  analyticsConfigKey="stakeholders"
                  analyticsGetter="statisticsStakeholders"
                  analyticsConfigDefaultKey="defaultStakeholderCode"
                  analyticsLocalStorageKey="stakeholderCode"
                  analyticsFunction="getStatistics"
                />
                <list-trending-topics
                  trendingTopicsType="statisticsTrendingTopics"
                />
              </v-expansion-panels>
              <list-agenda />
            </v-col>
          </v-row>
        </v-container>
      </template>

      <template v-slot:drawer-right>
        <saved-searches
          class="mt-n4"
          saved-searches-category-string="document"
          current-search-string="currentSearch"
          saved-searches-string="allSavedSearches"
          recent-searches-string="allRecentSearches"
          max-number-of-searches-string="maxNumberOfSearches"
          all-search-parameters-string="allSearchParameters"
          set-current-search-string="setCurrentSearch"
          remove-search-string="removeSearch"
          update-search-string="updateSearch"
          set-all-search-parameters-string="setAllSearchParameters"
          set-dialog-form-saved-searches-visible-string="setDocumentDialogFormSavedSearchesVisible"
          saved-searches-item-detail-recent-string="DocumentSavedSearchesItemDetailRecent"
          saved-searches-item-detail-saved-string="DocumentSavedSearchesItemDetailSaved"
        />
      </template>
    </base-layout>
  </div>
</template>

<script>
// Vuex
import { mapGetters } from 'vuex';

// @ is an alias to /src
import BaseLayout from '@/components/BaseLayout.vue';
import TrendAnalyticsChart from '@/components/TrendAnalyticsChart.vue';
import SearchBar from '@/components/SearchBar.vue';
import ListTimeline from '@/components/ListTimeline.vue';
import ListAgenda from '@/components/ListAgenda.vue';
import ListStakeholders from '@/components/ListStakeholders.vue';
import ListTrendingTopics from '@/components/ListTrendingTopics.vue';
import SavedSearches from '@/components/SavedSearches.vue';
import FeatureTeaserListManager from '@/components/FeatureTeaserListManager.vue';
import OldLinkWarning from '@/components/OldLinkWarning.vue';
import AgendaTeaserDialog from '@/modules/agenda/components/AgendaTeaserDialog.vue';
import { showDialog } from '@/helpers/dialogHelper';
import DocumentSavedSearchesForm from '@/modules/document/components/DocumentSavedSearchesForm.vue';
import Sidebar from '../components/DocumentSidebar.vue';

export default {
  name: 'dashboard',

  components: {
    DocumentSavedSearchesForm,
    BaseLayout,
    TrendAnalyticsChart,
    Sidebar,
    SearchBar,
    ListTimeline,
    ListAgenda,
    ListStakeholders,
    ListTrendingTopics,
    SavedSearches,
  },

  computed: {
    ...mapGetters({
      authHasModuleAccess: 'authHasModuleAccess',
      timelineItems: 'timelineItems',
      timelineItemsCountTotal: 'timelineItemsCountTotal',
      agendas: 'agendaAll',
      searchParameters: 'allSearchParameters',
      statisticsTrend: 'statisticsTrend',
      trendAnalyticsExpanded: 'trendAnalyticsExpanded',
      isIE11: 'isIE11',
    }),
  },

  data: () => ({
    userFiltersAreSet: false,
    toggleTrendAnalyticsExpanded: false,
    singlepanel: [0],
    panel: [0, 1, 2],
  }),

  methods: {

    gotToRoute(name) {
      this.$router.push({ name, query: { query: this.$route.query.query } });
    },

    showTeaserDialog() {
      showDialog(FeatureTeaserListManager);
    },
    showTeaserAgendaModule() {
      showDialog(AgendaTeaserDialog);
    },

    async parseQueryParams(queryParams) {
      const {
        query, context, groupPaths, documentTypes,
      } = queryParams;
      const dateRangeISO = {
        startDate: queryParams.startDate,
        endDate: queryParams.endDate,
      };

      // Check if there are any query parameters set
      if (
        query
        || context
        || groupPaths
        || documentTypes
        || dateRangeISO.startDate
        || dateRangeISO.endDate
      ) {
        if (documentTypes) {
          showDialog(OldLinkWarning);
        }

        if (query) {
          this.$store.dispatch('setQuery', query);
        }

        const contextsPresent = context && context.split(',').length > 0;
        if (contextsPresent) {
          this.$store.dispatch('setContext', context.split(','));
        }

        this.$store.dispatch('setGroupPaths', (groupPaths && groupPaths.split(',').length > 0) ? groupPaths.split(',') : []);

        if (this.$route.query.startDate && this.$route.query.endDate) {
          this.$store.dispatch('setDateRange', dateRangeISO);
        }
        if (query && contextsPresent) {
          this.$store.dispatch('getAllTimelineItems');
        }
      }
    },
  },

  watch: {
    trendAnalyticsExpanded(bool) {
      // Lets open the panels after the user altered the settings
      if (!bool) {
        this.panel = [0, 1, 2];
      } else {
        this.singlepanel = [0];
      }
    },
  },

  async mounted() {
    this.$gtag.pageview({
      page_path: '/dashboard',
    });
    // This is here because there is a glitch
    // when returning from another route
    await this.$store.dispatch('clearTimelineItems');
    await this.$store.dispatch('clearStatistics');
    await this.$store.dispatch('setCurrentSearch', {});
    await this.$store.dispatch('setRecentSearches', []);

    await this.$store.dispatch('getPreferences');
    this.$vuetify.theme.dark = this.$store.getters.userPreferences.uiDarkMode;

    // We want the filters for the user and a signal when they are available
    await this.$store.dispatch('getFilterTypesForUser').then(() => {
      this.userFiltersAreSet = true;
    });

    // If URL parameters are available, we need to set stuff accordingly
    await this.$store.dispatch('clearMessages');
    await this.parseQueryParams(this.$route.query);
    await this.$store.dispatch('getAllSavedSearches');
    this.$store.dispatch('getAgendaFilterTypesForUser');
    this.$store.dispatch('getEuroparlFilterTypesForUser');

    this.$store.dispatch('setIsIE11');
    if (this.isIE11) {
      this.panel = [1, 2];
    }
  },

};
</script>

<style lang="scss">
  ul.plain-li {
    & {
      list-style: none;
    }
    & li::before {
      content: "\2022";
      color: teal;
      font-weight: bold;
      display: inline-block;
      width: 1em;
      margin-left: -1em;
    }
  }
</style>
