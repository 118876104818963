<template>
  <div>
    <treeview
      class="px-2"
      v-model="checked"
      open-all
      selected-color="teal lighten-1"
      :items="items"
      @input="handleCheck"
      item-key="code"
      only-leaf-ids
    />
  </div>
</template>

<script>
import Treeview from '@/components/Treeview.vue';
import { mapGetters, mapActions, mapState } from 'vuex';
import EventBus from '../event-bus';

export default {
  components: {
    Treeview,
  },

  data() {
    return {
      filterType: 'context',
      checkedFilterChoices: [],
      // children: [],
    };
  },

  computed: {
    ...mapGetters({
      filters: 'userFilters',
    }),

    items() {
      return [{
        id: 0,
        code: 'DC',
        name: 'Thema’s (alles selecteren)',
        children: this.filters[this.filterType].map((item, id) => {
          let itemId = id;
          const res = Object.assign(item, { id: itemId += 1 });
          return res;
        }),
      }];
    },

    checked: {
      get() {
        const allSearchParameters = this.$store.getters.allSearchParameters[this.filterType];
        return allSearchParameters;
      },
      set(checkedFilterChoices) {
        this.checkedFilterChoices = checkedFilterChoices;
      },
    },
  },

  methods: {
    ...mapState(['searchParameters']),
    ...mapActions(['getAllTimelineItems', 'setCheckboxFilter']),

    handleCheck() {
      this.setCheckboxFilter({
        filterKey: this.filterType,
        filterValue: this.checkedFilterChoices,
      });
      this.setCheckBoxes();
    },

    setCheckBoxes() {
      const filter = {};
      filter[this.filterType] = this.checkedFilterChoices.join(',');

      // Set the selected filters as querystring of the dashboard
      this.$router.push({
        name: 'dashboard',
        query: { ...this.$route.query, ...filter },
      }).catch((err) => {
        // eslint-disable-next-line no-console
        console.log('route', err);
      });
      this.getAllTimelineItems();
    },
  },

  watch: {
    checked(val) {
      if (val.length === this.items[0].children.length) {
        // this.selectAll = false;
        EventBus.$emit('setMatchPercentageSlider', true);
      } else {
        EventBus.$emit('setMatchPercentageSlider', false);
      }
    },
  },

  mounted() {
    this.checkedFilterChoices = [];
    this.setCheckboxFilter({
      filterKey: this.filterType,
      filterValue: this.checkedFilterChoices,
    });
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .treeview {
    .v-input {
      .v-label {
        overflow: hidden;
        white-space: nowrap;
        .checkboxLabel {
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
    }

  }
}
</style>
