<template>
  <div>
    <v-container class="mt-n6">
      <v-row>
        <v-col>
          <v-subheader
            class="mb-n2 overline text-truncate"
          >
            Periode
          </v-subheader>
          <v-card
            class="pa-2"
          >
            <filter-date-range
              getTimelineItemsString="getAllTimelineItems"
            />
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-subheader
            class="mt-n4 mb-n2 overline text-truncate"
          >
            Minimale match
          </v-subheader>
          <v-card
            class="pa-2"
          >
            <filter-minimal-match-percentage />
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-subheader
            class="mt-n4 mb-n2 overline text-truncate"
          >
            Context
          </v-subheader>
          <v-card
            class="py-2"
          >
            <tree-context />
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-subheader
            class="mt-n4 mb-n2 overline text-truncate"
          >
            Bronnen
          </v-subheader>
          <v-card
            class="py-2"
          >
            <tree-document-types />
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <div
            class="my-14"
          />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import FilterDateRange from '@/components/FilterDateRange.vue';
import FilterMinimalMatchPercentage from '@/components/FilterMinimalMatchPercentage.vue';
import TreeContext from '@/components/TreeContext.vue';
import TreeDocumentTypes from '@/components/TreeDocumentTypes.vue';

export default {

  components: {
    FilterDateRange,
    FilterMinimalMatchPercentage,
    TreeContext,
    TreeDocumentTypes,
  },

  computed: {

  },

};
</script>
